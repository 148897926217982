<template>
  <div>
    <div class="page-title">
      <h3>Главная</h3>

      <!--<button class="btn waves-effect waves-light btn-small" @click="refresh">
        <i class="material-icons">refresh</i>
      </button>-->
    </div>

    <Loader v-if="loading"/>

    <div v-else class="row">
      <!--<HomeBill/>-->
    </div>
  </div>
</template>

<script>
//import HomeBill from '@/components/HomeBill'

export default {
  name: 'home',
  metaInfo() {
    return {
      title: 'Главная'
    }
  },
  data: () => ({
    loading: true,
    currency: null
  }),
  async mounted() {
    this.loading = false
  },
  methods: {
    async refresh() {
      this.loading = true
      this.loading = false
    }
  },
  components: {
    //HomeBill
  }
}
</script>
